import React, { useEffect, useState } from "react";
import "./General.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DialogueSt } from "../../utils/style";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { useTheme } from "@mui/material/styles";
import ReportTables from "../ReportTable/ReportTable";
import FeedIcon from "@mui/icons-material/Feed";
import SchoolIcon from "@mui/icons-material/School";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ExtensionIcon from "@mui/icons-material/Extension";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";
// import CloseIcon from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@material-ui/styles";
import ComponentLoader from "../../ComponentLoader";

function MonthlyReport({ head }) {
  let Navigate = useNavigate("");

  const NaviReportTab = () => {
    Navigate("/reporttable");
  };

  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [rolee, setRolee] = React.useState("");
  const isTrue = rolee === "Investigator";
  const [token, setToken] = useState("");
  const [month, setMonth] = useState(null);
  const [assM, setAssMonth] = useState("");

  const [directorID, setDirectorID] = useState(0);

  const [invis, setInvestigator] = useState();
  const [months, setMonths] = useState("");
  const [ins, setIns] = useState();
  const [state, setState] = useState();
  const theme = useTheme();
  const [disables, setDisables] = useState(false);
  const [monthData, setMonthData] = useState([]);

  const [listDataDepat, setListDataDepat] = useState([]);
  const [listDataInsti, setListDataInsti] = useState([]);
  const [isInvesIsTrue, setInvesIsTrue] = useState("");
  const [handledepat, setHandleDepat] = useState([]);
  const [llll, setIII] = useState("");
  const [directorate, setDirectorate] = useState();
  const [subitValid, setSubmitValid] = useState(false);
  const [loader, setLoader] = useState(true);

  const [masState, setMasState] = useState({
    inIv: 0,
    inTu: 0,
    diId: 0,
  });

  const [monthlyReport, setMonthlyReport] = useState();
  const [TrnMonthlyReport, setTrnMonthlyReport] = useState();

  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [inM, setIndMonth] = useState(null);
  const arr = [{ 202306: "June" }, { 202307: "July" }];
  const arrs = [{ month: "June" }, { month: "July" }];

  const classes = DialogueSt();

  useEffect(() => {
    // head("Monthly Report");

    const rolee = localStorage.getItem("role");
    setRolee(rolee);

    const tok = localStorage.getItem("token");
    setIII(tok);
    monthList(tok);
    const investigator = localStorage.getItem("loginId");
    const inst = localStorage.getItem("loginId");
    setInvestigator(investigator);
    if (rolee === "Director") {
      const investigator = localStorage.getItem("loginId");
      setDirectorID(investigator);
    } else if (rolee === "VC") {
      setDirectorID(1007);
    }
    setMasState((prev) => ({
      ...prev,
      diId: investigator,
    }));
    setToken(tok);
    setIns(inst);
    setToken(tok);

    console.log(tok);
    if (tok != null && tok != undefined) {
      handleChooseMonth(tok);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
      setMonths(Month);
      console.log("s---------------------------", Month);
      handleCountRender(Month, investigator, tok);
    }

    let dis = localStorage.getItem("disable");
    if (dis !== null) {
      setDisables(true);
    } else {
      setDisables(false);
    }

    let objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    console.log(myObject);
    if (objectString) {
      setMonthlyReport(myObject);
      setState(myObject);
      console.log(myObject.id);
    }
    if (rolee == "Principal") {
      handleDepat(tok, investigator);
      var directID = localStorage.getItem("DirectorID");
      setDirectorID(directID);
    }
    if (rolee === "Director" || rolee === "VC") {
      handleDepat(tok, investigator);
      const filterData = JSON.parse(localStorage.getItem("getinfoDId"));
      console.log(filterData);

      if (filterData) {
        setMasState((prev) => ({
          ...prev,
          inIu: filterData.InstituteId,
          inIv: filterData.InvestigatorId,
        }));
      }
      // handleInstitute(tok, investigator);
    } else {
      handleInstitute(tok, investigator);

      var facultyId = localStorage.getItem("facultyID");
      if (facultyId) {
        setMasState((prev) => ({
          ...prev,
          inIv: facultyId,
        }));
      }
    }

    let invesIsTrue = localStorage.getItem("role");
    setInvesIsTrue(invesIsTrue);
  }, []);

  useEffect(() => {
    let loginId = localStorage.getItem("loginId");
    const rolee = localStorage.getItem("role");
    let obj = {};
    if (rolee === "Director") {
      obj = {
        InvestigatorId: parseInt(masState.inIv),
        RptMonth: parseInt(assM),
        DirectorateId: parseInt(loginId),
        InstituteId: parseInt(masState.inTu),
      };
    }
    if (rolee === "Investigator") {
      obj = {
        InvestigatorId: parseInt(loginId),
        RptMonth: parseInt(assM),
      };
    }

    if (rolee === "Principal") {
      obj = {
        InvestigatorId: parseInt(masState.inIv) ? parseInt(masState.inIv) : 0,
        RptMonth: parseInt(assM),
        DirectorateId: parseInt(directorID),
        InstituteId: parseInt(masState.diId),
      };
    }

    const objectString = JSON.stringify(obj);
    localStorage.setItem("reportTableData", objectString);
  }, [masState.inIv, assM, masState.inTu, masState.diId]);

  const reload = () => {
    window.location.reload();
  };

  const ReportDownloadPageNavigate = () => {
    Navigate("/reportdownloadpage");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCountRender = async (value, id, token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(token);
    setAssMonth(value);
    setMonth(parseInt(value));
    let monthYear = arr.map((item) => Object.keys(item) == value);
    let ind = monthYear.findIndex((item) => item == true);
    setIndMonth(ind);
    localStorage.setItem("Month", value);
    const d = {
      InvestigatorId: parseInt(id),
      RptMonth: parseInt(value),
    };
    if (rolee === "") {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.COUNTNUMBER}`,
          d,
          {
            headers: h,
          }
        );
        console.log(data);
        if (data.monthlyReport?.length > 0) {
          setMonthlyReport(data.monthlyReport[0]);
          const objectString = JSON.stringify(data.monthlyReport[0]);
          localStorage.setItem("MReport", objectString);
          console.log(data);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  // Count Fetch API //
  const handleCount = async (value, opt, inds) => {
    console.log(value);
    const selectedText = opt[inds].text;
    console.log(selectedText);
    const storedArrayJSON = localStorage.getItem("myArrayKey");
    if (storedArrayJSON !== null) {
      const storedArray = JSON.parse(storedArrayJSON);
      console.log(storedArray);
      let some = storedArray.some((item) => item == selectedText);
      if (some) {
        // setDisables(true);
      } else {
        let stored = [];
        storedArray.map((item) => stored.push(item));
        stored.push(selectedText);
        localStorage.setItem("myArrayKey", JSON.stringify(stored));
        // setDisables(false);
      }
    } else {
      let myArray = [selectedText];
      localStorage.setItem("myArrayKey", JSON.stringify(myArray));
    }
    // Parse the JSON string back into an array

    // let get = localStorage.getItem()

    localStorage.setItem("Mname", selectedText);
    // console.log(selectedText.slice(5,));
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    setAssMonth(value);
    setMonth(parseInt(value));
    let monthYear = arr.map((item) => Object.keys(item) == value);
    let ind = monthYear.findIndex((item) => item == true);
    setIndMonth(ind);
    localStorage.setItem("Month", value);
    const d = {
      InvestigatorId: parseInt(invis),
      RptMonth: parseInt(value),
    };
    localStorage.removeItem("disable");

    // if()
  };

  const submitFun = async () => {
    if (assM === "") {
      alert("Please Select Month");
      return;
    }

    localStorage.setItem("btnss", true);
    console.log(token);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const d = {
      MRSId: state?.id,
      investigatorId: parseInt(ins),
      rptMonth: parseInt(assM),
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MONTHSUBMIT}`,
        d,
        { headers: h }
      );
      setLoader(false);
      console.log(data, "are u finding mee");

      if (data.result === true) {
        alert("Successfully Submitted");
        setDisables(true);
        // Disable the button after a successful submission
        localStorage.setItem("disable", true);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const monthList = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.MONTH_LIST}`, {
        headers: h,
      });
      console.log(data);
      setMonthData(data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleInstitute = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      console.log(data);
      //  setHandleDepat(data)
      setListDataInsti(data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleDepat = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEDEPARTMENT}`,
        {
          headers: h,
        }
      );
      console.log(data);
      //  setHandleDepat(data)
      setListDataDepat(data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  // Admin,Director Count //
  const AdminDirector = async (value) => {
    console.log(value);
    setMasState((prev) => ({
      ...prev,
      inIv: value,
    }));

    localStorage.setItem("facultyID", value);
  };

  // Report Fetch API //
  const handleChooseMonth = async (event) => {};

  const NextPage = (path) => {
    console.log(TrnMonthlyReport);
    if (assM !== "") {
      Navigate(path, { state: { TrnMonthlyReport: monthlyReport } });
    } else {
      alert("Please Select Month");
    }
  };
  console.log(assM);

  const getInvestigator = async (id) => {
    console.log(id);
    setMasState((prev) => ({
      ...prev,
      inTu: id,
    }));
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      console.log(data);
      setListDataInsti(data);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const getInfoInvestigator = async () => {
    localStorage.removeItem("MReport");
    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    console.log(invis);
    const d = {
      InvestigatorId: invis,
      RptMonth: assM !== "" ? parseInt(assM) : "",
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.COUNTNUMBER}`,
        d,
        {
          headers: h,
        }
      );
      console.log(data.monthlyReport[0].activeStatus);

      setLoader(false);
      if (data.monthlyReport?.length > 0) {
        setSubmitValid(true);
        if (data.monthlyReport[0].activeStatus === "Submitted") {
          setDisables(true);
          localStorage.setItem("disable", true);
        } else {
          setDisables(false);
          localStorage.removeItem("disable");
        }
        setMonthlyReport(data.monthlyReport[0]);
        const objectString = JSON.stringify(data.monthlyReport[0]);
        setState(data.monthlyReport[0]);
        localStorage.setItem("MReport", objectString);
      } else {
        setMonthlyReport({});
        localStorage.removeItem("MReport");
      }
      // setTimeout(function () {
      //   window.location.href = "/transactionleave";
      // }, 1000);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const getInfoPrincipal = async (d) => {
    if (!assM) {
      alert("Please choose month");
      return;
    }
    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    // let d = {
    //   InvestigatorId: masState.inIv > 0 ? parseInt(masState.inIv) : 0,
    //   RptMonth: assM !== "" ? parseInt(assM) : "",
    //   DirectorateId: parseInt(directorID),
    //   InstituteId: masState.diId > 0 ? parseInt(masState.diId) : 0,
    // };

   

    try {
      const { data } = await axios.post(
        `${window.BASE}${window.ADMINDIRECTOR}`,
        d,
        { headers: h }
      );
      console.log(data);
      setLoader(false);
      if (data.monthlyReport?.length > 0) {
        const di = JSON.stringify(d);
        localStorage.setItem("getinfoDId", di);
        setMonthlyReport(data.monthlyReport[0]);

        if (masState.inIv == 0) {
          let report = { ...data.monthlyReport[0] };
          report.id = 0;
          const objectString = JSON.stringify(report);
          localStorage.setItem("MReport", objectString);
        } else {
          const objectString = JSON.stringify(data.monthlyReport[0]);
          localStorage.setItem("MReport", objectString);
        }
      } else {
        setMonthlyReport({});
        localStorage.removeItem("MReport");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleReport = async (token, report) => {
    setLoader(true);
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.MONTHCHOOSE}`,
        report,
        { headers: h }
      );
      setLoader(false);
      if(data){
        let details=JSON.stringify(data[0].appModelDTO)
        localStorage.setItem("FacultyAll",details)
      }
    
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const getInfo = async (value, opt, inds) => {
    // let dis = localStorage.getItem("MReport");
    // const myObject = JSON.parse(dis);
    // if (myObject.activeStatus === "Submitted") {
    //   setDisables(true);
    // } else {
    //   setDisables(false);
    // }
    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    if (rolee === "Investigator") {
      getInfoInvestigator();
    } else if (rolee === "Principal") {
     let d = {
      InvestigatorId: masState.inIv > 0 ? parseInt(masState.inIv) : 0,
      RptMonth: assM !== "" ? parseInt(assM) : "",
      DirectorateId: parseInt(directorID),
      InstituteId: masState.diId > 0 ? parseInt(masState.diId) : 0,
    };
     await getInfoPrincipal(d);
     await  handleReport(llll,d)
    } else {
      let d = {
        InvestigatorId: masState.inIv > 0 ? parseInt(masState.inIv) : 0,
        RptMonth: assM !== "" ? parseInt(assM) : "",
        DirectorateId: directorID > 0 ? parseInt(directorID) : 0,
        InstituteId: masState.inTu > 0 ? parseInt(masState.inTu) : 0,
      };
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.ADMINDIRECTOR}`,
          d,
          { headers: h }
        );

        setLoader(false);
        if (data.monthlyReport?.length > 0) {
          const di = JSON.stringify(d);
          localStorage.setItem("getinfoDId", di);
          setMonthlyReport(data.monthlyReport[0]);
          if (masState.inIv == 0) {
            let report = { ...data.monthlyReport[0] };
            report.id = 0;
            const objectString = JSON.stringify(report);
            localStorage.setItem("MReport", objectString);
          } else {
            const objectString = JSON.stringify(data.monthlyReport[0]);
            localStorage.setItem("MReport", objectString);
          }
        } else {
          setMonthlyReport({});
          localStorage.removeItem("MReport");
        }
        await handleReport(llll,d)
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    }
  };

  return (
    <div style={{ marginTop: 60 }}>
      <ComponentLoader isLoading={loader} />
      <div
        style={{ height: "60px" }}
        className="headerr d-flex justify-content-between py-2 align-items-center"
      >
        <div className="d-flex ">
          <Link to={isTrue ? "/MasterDataFaculty" : "/MasterData"}>
            <HomeIcon
              style={{ marginTop: 12, marginLeft: 10, color: "white" }}
            />
          </Link>
          <h5 className="mt-3 mr-3">&nbsp;&nbsp;&nbsp;Filter:&nbsp;</h5>
          <div className="pr-2">
            <select
              value={assM}
              onChange={(e) => {
                handleCountRender();
                handleCount(
                  e.target.value,
                  e.target.options,
                  e.target.selectedIndex
                );
              }}
              id="months"
              name="months"
            >
              <option value="">Select Month</option>
              {monthData?.map((item) => (
                <option value={item.monthCode}> {item.monthName} </option>
              ))}
            </select>
          </div>
        </div>
        {isInvesIsTrue === "VC" ? (
          <>
            <select
              onChange={(e) => setDirectorate(e.target.value)}
              value={directorate}
              id="months2"
              name="months"
            >
              <option value={0}>TRRI </option>
            </select>
          </>
        ) : null}

        {isInvesIsTrue === "Investigator" ? null : (
          <div className="Admin-Select">
            {isInvesIsTrue === "Director" || isInvesIsTrue === "VC" ? (
              <>
                <select
                  onChange={(e) => getInvestigator(e.target.value)}
                  value={masState.inIu}
                  id="months"
                  name="months"
                >
                  <option value="Example">Institute</option>
                  {listDataDepat?.map((item) => (
                    <option value={item.id}>{item.instituteName}</option>
                  ))}
                </select>
                <select
                  onChange={(e) => AdminDirector(e.target.value)}
                  style={{ marginLeft: "5px" }}
                  id="months"
                  name="months"
                  value={masState.inIv}
                >
                  <option value={0}>All</option>
                  {listDataInsti?.map((item) => (
                    <option value={item.id}>{item.investigatorName}</option>
                  ))}
                </select>
              </>
            ) : null}
          </div>
        )}
        {isInvesIsTrue === "Principal" ? (
          <div className="Admin-Select">
            <select
              onChange={(e) => AdminDirector(e.target.value)}
              style={{ marginLeft: "5px" }}
              id="months"
              name="months"
              value={masState.inIv}
            >
              <option value={0}>All</option>
              {listDataInsti?.map((item) => (
                <option value={item.id}>{item.investigatorName}</option>
              ))}
            </select>
          </div>
        ) : null}
        <div className="header-btn mt-2 mr-2">
          <button onClick={getInfo}>Get Info</button>
        </div>
        <div className="header-btn mt-2 mr-2">
          <button onClick={ReportDownloadPageNavigate}>Report View</button>
          {subitValid ? (
            <button
              className="mt-2 mr-2"
              id="submitBtn"
              disabled={disables ? true : false}
              onClick={submitFun}
            >
              Submit
            </button>
          ) : null}
        </div>
      </div>

      <div className="report-btn">
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            className={classes.dialogue}
          >
            <DialogTitle id="responsive-dialog-title" className="Dialog_head">
              <div className="dialogueTittle">
                <div className="Dialog-head">
                  <h5>Report Download</h5>
                </div>
                <div onClick={handleClose} className="Cloase-icon">
                  <CloseIcon
                    style={{ marginBottom: "10" }}
                    className="dialogue_close_icon"
                  />
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <ReportTables />
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </div>

      <div class="overall_Box">
        <div className="box1fr rounded p-2 ">
          <div className="box1_inner1 rounded mb-2 p-box">
            <h4 className="cl-g">General Information</h4>
            {/* <hr></hr> */}
            <div className="number" style={{ marginTop: -13 }}>
              <span>
                <FeedIcon style={{ marginRight: 10 }} />{" "}
                <div onClick={() => NextPage("/transactionleave")}>
                  <Link style={{ color: "black" }}>Leave Information </Link>
                </div>
              </span>
              <span>{monthlyReport?.leaveCount}</span>
            </div>
            <div className="number">
              <span>
                <FeedIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/transactionOnduty")}>
                  <Link style={{ color: "black" }}>On Duty</Link>
                </div>
              </span>
              <span>{monthlyReport?.onDutyCount}</span>
            </div>
            <div className="number">
              <span>
                <FeedIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/TransactionTsms")}>
                  <Link style={{ color: "black" }}>TSMS</Link>
                </div>
              </span>
              <span>{monthlyReport?.tsmsCount}</span>
            </div>
          </div>

          <div className="box1_inner2 rounded p-box">
            <h4 className="cl-e">Educational Activities</h4>
            {/* <hr></hr> */}
            <div className="number" style={{ marginTop: -13 }}>
              <span>
                <SchoolIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/A1")}>
                  <Link style={{ color: "black" }}>(A1) Course Handling </Link>
                </div>
              </span>
              <span>{monthlyReport?.a1}</span>
            </div>

            <div className="number">
              <span>
                <SchoolIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/A2")}>
                  <Link style={{ color: "black" }}>(A2) Teaching Effort</Link>
                </div>
              </span>
              <span>{monthlyReport?.a2}</span>
            </div>
            <div className="number">
              <span>
                <SchoolIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/A3")}>
                  <Link style={{ color: "black" }}>(A3) Guest Lectures</Link>
                </div>
              </span>
              <span>{monthlyReport?.a3}</span>
            </div>
            <div className="number">
              <span>
                <SchoolIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/A4")}>
                  <Link style={{ color: "black" }}>(A4) Exposure visits</Link>
                </div>
              </span>
              <span>{monthlyReport?.a4}</span>
            </div>
            <div className="number">
              <span>
                <SchoolIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/A5")}>
                  <Link style={{ color: "black" }}>(A5) Student Guidance</Link>
                </div>
              </span>
              <span>{monthlyReport?.a5}</span>
            </div>
            <div className="number">
              <span>
                <SchoolIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/A6")}>
                  <Link style={{ color: "black" }}>(A6) Advisory Chairman</Link>
                </div>
              </span>
              <span>{monthlyReport?.a6}</span>
            </div>
            <div className="number">
              <span>
                <SchoolIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/A7")}>
                  <Link style={{ color: "black" }}>
                    (A7) Teaching Activities
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.ta}</span>
            </div>
            <div className="number">
              <span>
                <SchoolIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/A8")}>
                  <Link style={{ color: "black" }}>
                    (A8) Student facilitation
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.esf}</span>
            </div>
          </div>
        </div>

        <div className="box2fr rounded p-2">
          <div className="box2_inner1 rounded mb-2 p-box">
            <h4 className="cl-g">Research Activities</h4>
            {/* <hr></hr> */}
            <div className="number" style={{ marginTop: -15 }}>
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B1")}>
                  <a> (B1) Research Area</a>
                </div>
              </span>
              <span>{monthlyReport?.b1}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B1")}>
                  <a>(B2) Crop</a>
                </div>
              </span>
              <span>{monthlyReport?.b2}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B3")}>
                  <Link style={{ color: "black" }}>(B3) Target product</Link>
                </div>
              </span>
              <span>{monthlyReport?.b3}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B4")}>
                  <Link style={{ color: "black" }}>
                    (B4) Translational Pathway{" "}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b4}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B5")}>
                  <Link style={{ color: "black" }}>
                    (B5) New Variety Product
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b5}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B6")}>
                  <Link style={{ color: "black" }}>
                    (B6) Germplasm collection
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b6}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B7")}>
                  <Link style={{ color: "black" }}>
                    (B7) Patents registration
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b7}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B8")}>
                  <Link style={{ color: "black" }}>(B8) Academic Linkages</Link>
                </div>
              </span>
              <span>{monthlyReport?.b8}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B9")}>
                  <Link style={{ color: "black" }}>
                    (B9) MoUs Current Month
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b9}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B10")}>
                  <Link style={{ color: "black" }}>
                    (B10) Research Projects{" "}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b10}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B11")}>
                  <Link style={{ color: "black" }}>
                    (B11) MLT/OFT/Action Plan
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b11}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B12")}>
                  <Link style={{ color: "black" }}>(B12) New Project</Link>
                </div>
              </span>
              <span>{monthlyReport?.b12}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B13")}>
                  <Link style={{ color: "black" }}>
                    (B13) completed projects
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b13}</span>
            </div>
          </div>

          <div className="box2_inner2 rounded p-box"></div>
        </div>

        <div className="box3fr rounded p-2">
          <div className="box3_inner1 rounded mb-2 p-box">
            <h4 className="cl-g">Research Activities</h4>
            <div className="number" style={{ marginTop: 5 }}>
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B14")}>
                  <Link style={{ color: "black" }}>(B14) Publications</Link>
                </div>
              </span>
              <span>{monthlyReport?.b14}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B15")}>
                  <Link style={{ color: "black" }}>(B15) Books Chapters</Link>
                </div>
              </span>
              <span>{monthlyReport?.b15}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B16")}>
                  <Link style={{ color: "black" }}>(B16) Seminar Articles</Link>
                </div>
              </span>
              <span>{monthlyReport?.b16}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B17")}>
                  <Link style={{ color: "black" }}>(B17) Popular articles</Link>
                </div>
              </span>
              <span>{monthlyReport?.b17}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B18")}>
                  <Link style={{ color: "black" }}>
                    (B18) Workshop Attended
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b18}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B19")}>
                  <Link style={{ color: "black" }}>
                    (B19) Workshop organized
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b19}</span>
            </div>
            <div className="number">
              <span>
                <ManageSearchIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/B20")}>
                  <Link style={{ color: "black" }}>
                    (B20) Plenary delivered
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b20}</span>
            </div>
          </div>

          <div className="box3_inner2 rounded p-box">
            <h4 className="cl-e">Extension Activities</h4>
            {/* <hr></hr> */}
            <div className="number" style={{ marginTop: -15 }}>
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C1")}>
                  <Link style={{ color: "black" }}>(C1) T & M Organized</Link>
                </div>
              </span>
              <span>{monthlyReport?.c1}</span>
            </div>
            <div className="number">
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C2")}>
                  <Link style={{ color: "black" }}>(C2) T & M attended</Link>
                </div>
              </span>
              <span>{monthlyReport?.c2}</span>
            </div>
            <div className="number">
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C3")}>
                  <Link style={{ color: "black" }}>
                    (C3) OFT/FLD/FS organized
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c3}</span>
            </div>
            <div className="number">
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C4")}>
                  <Link style={{ color: "black" }}>
                    (C4) F V and Farm Advisory
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c4}</span>
            </div>
          </div>
        </div>

        <div className="box4fr rounded p-2">
          <div className="box4_inner1 rounded mb-2 p-box">
            <h4 className="cl-e">Extension Activities </h4>

            <div className="number">
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C5")}>
                  <Link style={{ color: "black" }}>
                    (C5) Extension Activities{" "}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c5}</span>
            </div>
            <div className="number">
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C6")}>
                  <Link style={{ color: "black" }}>
                    (C6) Editorial services
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c6}</span>
            </div>
            <div className="number">
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C7")}>
                  <Link style={{ color: "black" }}>
                    (C7) Extension Services
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c7}</span>
            </div>
            <div className="number">
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C8")}>
                  <Link style={{ color: "black" }}>
                    (C8) FPO & SHG Facilitated
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c8}</span>
            </div>
            <div className="number">
              <span>
                <ExtensionIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/C9")}>
                  <Link style={{ color: "black" }}>(C9) Policy Documents </Link>
                </div>
              </span>
              <span>{monthlyReport?.c9}</span>
            </div>
          </div>

          <div className="box4_inner2 rounded p-box">
            <h4 className="cl-g">Institutional Development</h4>
            {/* <hr></hr> */}
            <div className="number" style={{ marginTop: -15 }}>
              <span>
                <MenuBookIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/D1")}>
                  <Link style={{ color: "black" }}>
                    (D1) Production of seeds
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.d1}</span>
            </div>
            <div className="number">
              <span>
                <MenuBookIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/D2")}>
                  <Link style={{ color: "black" }}>
                    (D2) Ad-Responsibilities
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.d2}</span>
            </div>
            <div className="number">
              <span>
                <MenuBookIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/D3")}>
                  <Link style={{ color: "black" }}>
                    (D3) Academic Responsi..
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.d3}</span>
            </div>
            <div className="number">
              <span>
                <MenuBookIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/D4")}>
                  <Link style={{ color: "black" }}>
                    (D4) Individual scientist
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.d4}</span>
            </div>
            <div className="number">
              <span>
                <MenuBookIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/D5")}>
                  <Link style={{ color: "black" }}>
                    (D5) Equipment purchased
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.d5}</span>
            </div>
            <div className="number">
              <span>
                <MenuBookIcon style={{ marginRight: 10 }} />
                <div onClick={() => NextPage("/D6")}>
                  <Link style={{ color: "black" }}>(D6) Fellowship</Link>
                </div>
              </span>
              <span>{monthlyReport?.d6}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthlyReport;
